import React from 'react'
import styled from 'styled-components'

function MovieRecommended() {
  return (
    <Wrapper>
      <div>MovieRecommended</div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default MovieRecommended
