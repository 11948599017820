import React from 'react'
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi'
import imgOne from '../images/hero/6.png'
import imgTwo from '../images/hero/2.png'

import imgThree from '../images/travel/travelhero.jpg'
import imgFour from '../images/hero/property2.jpg'

import imgFive from '../images/hero/5.png'
// import imgSix from '../images/hero/1.png'

// ////////staff images
import staffOne from '../images/team/default.jpg'
import ceoImg from '../images/team/ceo/ceo.jpg'
// team congo
import rock from '../images/team/congo/rock.jpeg'
import eu from '../images/team/congo/eu.jpg'
import ad from '../images/team/congo/ad.jpeg'

// south africa
import erasH from '../images/team/southafrica/erasmush.jpg'
import erasW from '../images/team/southafrica/erasmusw.jpg'
import larse from '../images/team/southafrica/larse.jpeg'

import vidThree from '../images/movies/movie3.mp4'
import vidFour from '../images/movies/movie4.mp4'
import vidFive from '../images/movies/movie5.mp4'

import cosmeticImageOne from '../images/cosmetic/4.png'
import cosmeticImageTwo from '../images/cosmetic/5.png'

import img1 from '../images/behindsceneimage/img1.jpg'
import img2 from '../images/behindsceneimage/img2.jpg'
import img3 from '../images/behindsceneimage/img3.jpg'
import img4 from '../images/behindsceneimage/img4.jpg'
import img5 from '../images/behindsceneimage/img5.jpg'
import img6 from '../images/behindsceneimage/img6.jpg'
import img7 from '../images/behindsceneimage/img7.jpg'
import img8 from '../images/behindsceneimage/img8.jpg'
import img9 from '../images/behindsceneimage/img9.jpg'
import img10 from '../images/behindsceneimage/img10.jpg'
import img11 from '../images/behindsceneimage/img11.jpg'
import img12 from '../images/behindsceneimage/img12.jpg'
import img13 from '../images/behindsceneimage/img13.jpg'
import img14 from '../images/behindsceneimage/img14.jpg'
import img15 from '../images/behindsceneimage/img15.jpg'
import img16 from '../images/behindsceneimage/img16.jpg'
import img17 from '../images/behindsceneimage/img17.jpg'
import img18 from '../images/behindsceneimage/img18.jpg'

import foodOne from '../images/food/4.png'
import foodWater from '../images/food/8.png'
import foodTwo from '../images/food/9.png'

export const movieGallery = [
  {
    urlImg: img1,
    altImg: 'the image',
  },
  {
    urlImg: img2,
    altImg: 'the image',
  },
  {
    urlImg: img3,
    altImg: 'the image',
  },
  {
    urlImg: img4,
    altImg: 'the image',
  },
  {
    urlImg: img5,
    altImg: 'the image',
  },
  {
    urlImg: img6,
    altImg: 'the image',
  },
  {
    urlImg: img7,
    altImg: 'the image',
  },
  {
    urlImg: img8,
    altImg: 'the image',
  },
  {
    urlImg: img9,
    altImg: 'the image',
  },
  {
    urlImg: img10,
    altImg: 'the image',
  },
  {
    urlImg: img11,
    altImg: 'the image',
  },
  {
    urlImg: img12,
    altImg: 'the image',
  },
  {
    urlImg: img13,
    altImg: 'the image',
  },
  {
    urlImg: img14,
    altImg: 'the image',
  },
  {
    urlImg: img15,
    altImg: 'the image',
  },
  {
    urlImg: img16,
    altImg: 'the image',
  },
  {
    urlImg: img17,
    altImg: 'the image',
  },
  {
    urlImg: img18,
    altImg: 'the image',
  },
]

export const movierecomnd = [
  {
    vid: vidThree,
    title: 'Beautiful lies',
    year: '2023',
    genre: 'Movie',
    rated: 'PG',
  },
  {
    vid: vidFour,
    title: 'Mafia 2',
    year: '2023',
    genre: 'Movie',
    rated: 'PG',
  },
  {
    vid: vidFive,
    title: 'African Connection',
    year: '2022',
    genre: 'Movie',
    rated: 'PG',
  },
]

export const heroimage = [
  {
    url: imgOne,
    alt: 'first',
    title: 'Slide 1',
    info: 'this is the first paragraph',
  },
  {
    url: imgTwo,
    alt: 'second',
    title: 'Slide 2',
    info: 'this is the two paragraph',
  },
  {
    url: imgThree,
    alt: 'three',
    title: 'Slide 3',
    info: 'this is the three paragraph',
  },
  {
    url: imgFour,
    alt: 'four',
    title: 'Slide 4',
    info: 'this is the four paragraph',
  },
  {
    url: imgFive,
    alt: 'five',
    title: 'Slide 5',
    info: 'this is the five paragraph',
  },
  // {
  //   url: imgSix,
  //   alt: 'six',
  //   title: 'Slide 6',
  //   info: 'this is the six paragraph',
  // },
]

export const goals = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text: 'Our mission at Royal Memoria is to inspire and empower individuals, businesses, and communities to thrive by delivering innovative solutions, unparalleled service, and sustainable value.  ',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'motto',
    text: 'Our motto is to solidify our reputation as one of the most trusted companies, enabling us to expand our business across diverse sectors including creative, technical, and other industries.',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'history',
    text: 'Founded in 2016 by Memoria Pungwe in South Africa, Royal Memoria swiftly evolved into a multi-service company. Since July 2021, our footprint has expanded to Canada and the Democratic Republic of Congo.',
  },
]

export const charity = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text: ' Our Mission is to empower communities through sustainable initiatives in education, healthcare, and economic development. We strive to provide resources, support, and advocacy to underserved populations, fostering a culture of inclusivity and equality',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'Vision',
    text: 'Our Vision is to create a world where every children has access to education, healthcare, and opportunities for personal growth.',
  },
]

export const foodData = [
  {
    id: 1,
    images: foodOne,
    name: 'Royal Burger',
    price: 17,
    category: 'food',
    description:
      'This is a Combination of Sweetness and juiciness.A burger that passes the "burger test" is typically one that meets or exceeds expectations in terms of taste, texture, and overall satisfaction.',
    stock: 0,
    colors: ['black'],
    stars: 4.5,
    reviews: 23,
  },
  {
    id: 2,
    images: foodWater,
    name: 'Water Kitoko',
    price: 8,
    category: 'Water',
    description:
      'Produced our own sparkling water brand "Memoria"! Sparkling water has gained popularity as a refreshing and healthier alternative to sugary sodas and beverages. We have establish "Memoria" as a trusted and preferred sparkling water brand among our consumers.',
    stock: 0,
    colors: ['black'],
    stars: 4,
    reviews: 10,
  },
  {
    id: 3,
    images: foodTwo,
    name: 'Chips',
    price: 9,
    category: 'food',
    description:
      'We do it without oil, Cooking fries without oil can indeed reduce the fat content compared to traditional deep-frying methods. This method typically involves baking or air frying the fries rather than immersing them in oil.',
    stock: 10,
    colors: ['black', 'gray', 'darkorange', 'green'],
    stars: 4.4,
    reviews: 15,
  },
]

export const cosmeticData = [
  {
    id: 1,
    images: cosmeticImageOne,
    name: 'PM Perfume',
    price: 4500,
    category: 'Perfume',
    description:
      'This perfume has a Floral scents, associated with freshness, elegance, and femininity, it can evoke various emotions and impressions.',
    stock: 0,
    colors: ['black'],
    stars: 4.5,
    reviews: 23,
  },
  {
    id: 2,
    images: cosmeticImageTwo,
    name: 'Pocket Perfume',
    price: 2500,
    category: 'Perfume',
    description:
      'Pocket perfumes are indeed miniature versions of regular perfumes, designed for easy portability and convenience.They are available for both men and women.',
    stock: 0,
    colors: ['black'],
    stars: 4,
    reviews: 10,
  },
]

export const ceo = [
  {
    id: 1,
    icon: ceoImg,
    title: 'FOUNDER AND CEO of ROYAL MEMORIA ',
    text: 'Memoria Pungwe ',
    descriptionOne:
      "Memoria Pungwe is the Founder and CEO of Royal Memoria, a dynamic leader with a rich background in public relations, communication, and media arts. Armed with a bachelor's degree in public relations and communication, as well as a certificate in media arts, she embarked on a successful career as a screenwriter and editor, notably crafting compelling narratives for South African films. ",
    descriptionTwo:
      'Beyond her creative pursuits, Memoria is on the brink of venturing into the literary world with an upcoming book aimed at transporting readers into the realm of "phenomenology," showcasing her versatility and depth as a storyteller.',
    descriptionThree:
      "However, Memoria's impact extends far beyond the realms of art and entertainment. She is deeply committed to empowering women and advocating for the rights of voiceless girls through her non-profit association. With a team of dedicated lawyers, she works tirelessly to combat violence and abuse, championing the slogan 'Women are stronger than they are.'",
    descriptionFour:
      "Moreover, Memoria's love for children is evident in her extensive experience as a family support worker across various childcare programs and family resource initiatives. She creates nurturing environments where children feel safe and supported, recognizing the importance of positive interactions in shaping young minds.",
    descriptionFive:
      "Memoria's multifaceted approach to leadership, coupled with her unwavering dedication to empowering women and nurturing children, embodies the core values of Royal Memoria and serves as a beacon of inspiration for positive change within communities.",
  },
]

export const teamCanada = [
  {
    id: 1,
    icon: ceoImg,
    title: 'Memoria Pungwe',
    text: 'Founder And CEO',
  },
  {
    id: 2,
    icon: staffOne,
    title: 'Isreal Amboko',
    text: 'Graphic Design',
  },
  {
    id: 3,
    icon: staffOne,
    title: 'Jocelyn Pungwe',
    text: 'Software Engineer',
  },
]

export const teamSouthAfrica = [
  {
    id: 1,
    icon: rock,
    title: 'Rock Bokeka',
    text: 'Film Director & Administrative ',
  },
  {
    id: 2,
    icon: erasW,
    title: 'Marie Louise Rossouw',
    text: 'Actress & Script Writter',
  },
  {
    id: 3,
    icon: erasH,
    title: 'Claude Rossouw',
    text: 'Editor',
  },
  {
    id: 4,
    icon: staffOne,
    title: 'Roddi',
    text: 'Marketing Director',
  },
  {
    id: 5,
    icon: staffOne,
    title: 'Emmanuel Mbikavu',
    text: 'Real Estate Agent',
  },
  {
    id: 6,
    icon: staffOne,
    title: 'Fidel Banza',
    text: 'Network Engineer',
  },
]
export const teamCongo = [
  // {
  //   id: 1,
  //   icon: rock,
  //   title: 'Rock Bokeka',
  //   text: 'Managing Director',
  // },
  {
    id: 2,
    icon: eu,
    title: 'Eunice Mbulu',
    text: 'Head Of Administration',
  },
  {
    id: 3,
    icon: ad,
    title: 'Shekinah Lusangu',
    text: 'Administrative Assistance',
  },
]
